<template>
  <div class="container">
    <div v-if="showLegend" class="legend">
      <div
        v-for="(label, index) in chartData.legend"
        :key="label"
        class="label"
      >
        <div
          class="color"
          :style="{
            backgroundColor: colors[index] || 'gray'
          }"
        ></div>
        <div>{{ label }}</div>
      </div>
    </div>
    <div class="chart-container">
      <div class="yAxis" v-if="showXAxis">
        <div>
          <div v-for="value in [100, 80, 60, 40, 20, 0]" :key="value">
            {{ value }} %
          </div>
        </div>
      </div>
      <div class="bars-container">
        <div class="chart-lines">
          <div v-for="value in 5" :key="value"></div>
        </div>
        <template v-if="chartData.dataset">
          <div
            v-for="(key, indexKey) in Object.keys(
              chartData.dataset
            ).sort((a, b) =>
              parseInt(chartData.dataset[a][0]) >
              parseInt(chartData.dataset[b][0])
                ? -1
                : parseInt(chartData.dataset[b][0]) >
                  parseInt(chartData.dataset[a][0])
                ? 1
                : 0
            )"
            :style="{
              width: 100 / Object.keys(chartData.dataset).length + '%'
            }"
            :key="key"
          >
            <div class="bars">
              <div
                v-for="(value, index) in firstOnly
                  ? chartData.dataset[key].slice(0, 1)
                  : chartData.dataset[key]"
                class="bar-container"
                :style="{
                  width:
                    100 / (chartData.legend.length - (firstOnly ? 1 : 0)) + '%'
                }"
                :key="index"
              >
                <div
                  class="bar"
                  :class="{
                    circleShape: isShapeCircle,
                    short: Object.keys(chartData.dataset).length < 3
                  }"
                  :style="{
                    height: value + '%',
                    backgroundColor:
                      colors[firstOnly ? indexKey : index] || 'gray'
                  }"
                >
                  <div class="value">{{ value }}%</div>
                </div>
              </div>
            </div>
            <div class="labels">
              <p>{{ key }}</p>
              <span></span>
              <span class="tooltip">{{ key }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },

  props: {
    chartData: {
      type: Object,
      required: true
    },
    colors: {
      type: Array,
      required: true
    },
    showXAxis: {
      type: Boolean,
      required: false,
      default: false
    },
    isShapeCircle: {
      type: Boolean,
      default: true,
      required: false
    },
    firstOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    showLegend: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  methods: {},

  mounted() {},

  computed: {}
};
</script>

<style scoped>
.container {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 98%;
}

.legend .label {
  display: flex;
  align-items: center;
  gap: 1em;
}

.legend .label div {
  font-size: 1em;
}

.chart-container {
  display: flex;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 0.3em;
}

.bars-container {
  height: 72%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d1d1d1;
  border-left: 1px solid #d1d1d1;
  flex-grow: 1;
  margin: auto;
  position: relative;
}

.bars {
  height: 100%;
  width: 100%;
  display: flex;
  border-right: 1px solid #d1d1d1;
}

.bar-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-end;
}

.bar {
  width: 40%;
  z-index: 0;
  position: relative;
}

.bar.circleShape {
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
}

.bar.short {
  width: 15%;
}

.bar:hover {
  filter: brightness(110%);
}

.labels {
  text-align: center;
  margin-top: 0.3em;
  overflow-wrap: break-word;
  padding-top: 0;
  position: relative;
}

.labels p {
  top: 0;
  left: 0;
  width: 95%;
  cursor: help;
  min-width: 15em;
  padding: 0 1.8em;
  margin: auto;
  line-height: 120%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  /* max-height: 2.4em; */
  position: relative;
}

.labels .tooltip {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  white-space: nowrap;
  color: white;
  text-align: center;
  border-radius: var(--lengthSm1);
  padding: 0.8em 1.5em;
  position: absolute;
  left: 30%;
  top: -3.1em;
  z-index: 1;
}

.labels:hover .tooltip {
  visibility: visible;
}

.value {
  margin-bottom: 1em;
  height: fit-content;
  position: absolute;
  top: -2em;
  width: 100%;
  text-align: center;
}

.yAxis {
  height: 72%;
  min-width: 4em;
  max-width: 4em;
  position: relative;
  margin-right: 1%;
}
.yAxis > div {
  position: absolute;
  height: 100%;
  width: 100%;
  top: -18%;
}

.yAxis div div {
  display: flex;
  align-items: flex-end;
  height: 20%;
}

.color {
  width: 1.5em;
  height: 0.5em;
}

.legend {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 0.1em;
  margin-top: 0.1em;
  gap: 0.1em;
}

.chart-lines {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  position: absolute;
  top: 0;
}

.chart-lines div {
  width: 100%;
  border-top: solid 0.5px #d1d1d191;
  flex-grow: 1;
}
</style>
