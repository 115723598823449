<template>
  <div class="container">
    <template v-for="filter in Object.keys(filters)">
      <div
        v-for="(value, index) in filters[filter]"
        :key="index + value"
        class="tag"
      >
        <h5>{{ value }}</h5>
      </div>
    </template>
    <template v-if="selectedDimensions">
      <div
        v-for="dimensionId in Object.keys(selectedDimensions)"
        :key="dimensionId"
        class="tag"
      >
        <h5>{{ selectedDimensions[dimensionId] }}</h5>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },

  props: {
    filters: {
      type: Object,
      required: true
    },
    selectedDimensions: {
      type: Object,
      required: false,
      default: null
    }
  },

  methods: {},

  mounted() {},

  computed: {}
};
</script>

<style scoped>
.container {
  display: flex;
  padding-right: 1em;
  align-items: center;
}

.container > div {
  margin-bottom: var(--lengthLg1);
  border: solid 3px #f7f7fc;
  border-radius: 10px;
  height: 1.6em;
}

.container h4 {
  font-weight: var(--weight-semi-bold);
  padding: 0.2em;
  padding-left: 0.7em;
  color: #575757;
  background-color: #f7f7fc;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.container .tag {
  margin: var(--lengthSm2);
  padding: 0.4rem var(--lengthSm3);
  border-radius: var(--lengthMd3);
  background-color: #f7f7fc;
  display: flex;
  align-items: center;
}

.container h5 {
  display: inline;
}
</style>
