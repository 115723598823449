<template>
  <div class="container" @click="changeChart">
    <div>
      <img :src="require('@/assets/' + chartIcon)" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ChartSelect",
  data() {
    return {};
  },
  props: {
    value: {
      default: "",
      type: String
    },
    charts: {
      type: Array
    }
  },

  methods: {
    changeChart() {
      let index =
        this.charts.indexOf(this.value) + 1 > this.charts.length - 1
          ? 0
          : this.charts.indexOf(this.value) + 1;
      this.$emit("input", this.charts[index]);
    }
  },
  computed: {
    chartIcon() {
      return this.value === "squareBar"
        ? "barSquareChart.svg"
        : this.value === "roundBar"
        ? "barCircleChart.svg"
        : this.value === "line"
        ? "lineChart.svg"
        : "barClockChart.svg";
    }
  },
  mounted() {}
};
</script>

<style scoped>
.container {
  padding: 0.6em;
  background-color: var(--grayColor1);
  border-radius: 4px;
  cursor: pointer;
}

.container:hover {
  background-color: var(--grayColor2);
}

.container img {
  height: 25px;
  width: 25px;
}
</style>
